<template>
  <div class="hello">   
  

    
     <button v-on:click="GetRandomURL">Next</button>

  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data: function () {
  return {
    RandomURL: "https://i.imgur.com/fHeCwNA.png" //"https://prnt.sc/"+ Math.random().toString(36).substring(7)+"/direct.png",//Fff

  }
},
  methods:{

GetRandomURL () {
  this.RandomURL="https://prnt.sc/"+ Math.random().toString(36).substring(7);//+"/direct";
window.open(this.RandomURL)
window.focus();
   
   


 
  console.log(this.RandomURL)
}

  }
 
}

/*
<iframe v-bind:src="RandomURL" width="100%" height="100%">
  <p>Your browser does not support iframes.</p>
</iframe>
<img v-bind:src="RandomURL"/>
*/

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
